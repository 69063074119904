import requests from '../../request'

const baseUrl = '/purchase/order';
const selectorBaseUrl = '/selector';
const region = 'cloud-api';
const ContentTypeEnum = {
    // json
    JSON: 'application/json;charset=UTF-8',
    // form-data qs
    FORM_URLENCODED: 'application/x-www-form-urlencoded;charset=UTF-8',
    // form-data  upload
    BLOB: 'multipart/form-data;charset=UTF-8',
}
export function selector(
    params
) {
    return requests(
        {
            url: selectorBaseUrl + '/purchaseorder',
            params,
            method: 'get'
        },
    );
}

export function loadPurchaseOrder(ids) {
    return requests(
        {
            url: selectorBaseUrl + '/purchaseorder/load',
            data: ids,
            method: 'post'
        },
        {
            contentType: ContentTypeEnum.JSON,
        },
    );
}

/**
 * 打印
 */
export function print(id) {
    return requests(
        {
            url: baseUrl + '/print',
            params: {
                id,
            },
            method: 'get'
        },
    );
}

/**
 * 订单列表
 */
export function query(params) {
    return requests(
        {
            url: baseUrl + '/query',
            params,
            method: 'get'
        },
    );
}

/**
 * 导出
 */
export function exportList(data) {
    return requests(
        {
            url: baseUrl + '/export',
            data,
            method: 'post'
        },
        {
            responseType: ResponseEnum.BLOB,
            contentType: ContentTypeEnum.FORM_URLENCODED,
        },
    );
}

/**
 * 查询详情
 */
export function get(id) {
    return requests(
        {
            url: baseUrl,
            params: {
                id,
            },
            method: 'get'
        },
    );
}

/**
 * 根据ID查询（收货业务）
 */
export function getWithReceive(id) {
    return requests(
        {
            url: baseUrl + '/receive',
            params: {
                id,
            },
            method: 'get'
        },
    );
}

/**
 * 查询列表（收货业务）
 */
export function queryWithReceive(
    params
) {
    return requests(
        {
            url: baseUrl + '/query/receive',
            params,
            method: 'get'
        },
    );
}

/**
 * 加载列表（收货业务）
 */
export function loadWithReceive(ids) {
    return requests(
        {
            url: baseUrl + '/query/receive/load',
            data: ids,
            method: 'post'
        },
        {
            contentType: ContentTypeEnum.JSON,
        },
    );
}

/**
 * 新增
 */
export function create(data) {
    return requests(
        {
            url: baseUrl,
            data,
            method: 'post'
        },
        {
            contentType: ContentTypeEnum.JSON,
        },
    );
}

/**
 * 修改
 */
export function update(data) {
    return requests(
        {
            url: baseUrl,
            data,
            method: 'put'
        },
        {
            contentType: ContentTypeEnum.JSON,
        },
    );
}

/**
 * 审核通过
 */
export function approvePass(data) {
    return requests(
        {
            url: baseUrl + '/approve/pass',
            data,
            method: 'patch'
        },
        {
            contentType: ContentTypeEnum.JSON,
        },
    );
}

/**
 * 批量审核通过
 */
export function batchApprovePass(data) {
    return requests(
        {
            url: baseUrl + '/approve/pass/batch',
            data,
            method: 'patch'
        },
        {
            contentType: ContentTypeEnum.JSON,
        },
    );
}

/**
 * 直接审核通过
 */
export function directApprovePass(data) {
    return requests(
        {
            url: baseUrl + '/approve/pass/direct',
            data,
            method: 'post'
        },
        {
            contentType: ContentTypeEnum.JSON,
        },
    );
}

/**
 * 审核拒绝
 */
export function approveRefuse(data) {
    return requests(
        {
            url: baseUrl + '/approve/refuse',
            data,
            method: 'patch'
        },
        {

            contentType: ContentTypeEnum.JSON,
        },
    );
}

/**
 * 批量审核拒绝
 */
export function batchApproveRefuse(data) {
    return requests(
        {
            url: baseUrl + '/approve/refuse/batch',
            data,
            method: 'patch'
        },
        {

            contentType: ContentTypeEnum.JSON,
        },
    );
}

/**
 * 删除
 */
export function deleteById(params) {
    return requests(
        {
            url: baseUrl,
            params,
            method: 'delete'
        },
        {

            contentType: ContentTypeEnum.FORM_URLENCODED,
        },
    );
}

/**
 * 批量删除
 */
export function deleteByIds(ids) {
    return requests(
        {
            url: baseUrl + '/batch',
            data: ids,
            method: 'delete'
        },
        {

            contentType: ContentTypeEnum.JSON,
        },
    );
}

/**
 * 取消审核
 */
export function cancelApprovePass(id) {
    return requests(
        {
            url: baseUrl + '/approve/cancel',
            data: {
                id,
            },
            method: 'patch'
        },
        {

            contentType: ContentTypeEnum.FORM_URLENCODED,
        },
    );
}

/**
 * 下载导入模板
 */
export function downloadImportTemplate() {
    return requests(
        {
            url: baseUrl + '/import/template',
            method: 'get'
        },
        {
            responseType: ResponseEnum.BLOB,

        },
    );
}

/**
 * 导入
 */
export function importExcel(data) {
    return requests(
        {
            url: baseUrl + '/import',
            data,
            method: 'post'
        },
        {
            contentType: ContentTypeEnum.BLOB,

        },
    );
}

/**
 * 下载约定支付导入模板
 */
export function downloadImportPayTypeTemplate() {
    return requests(
        {
            url: baseUrl + '/import/template/paytype',
            method: 'get'
        },
        {
            responseType: ResponseEnum.BLOB,

        },
    );
}

/**
 * 导入约定支付
 */
export function importPayTypeExcel(data) {
    return requests(
        {
            url: baseUrl + '/import/paytype',
            data,
            method: 'post'
        },
        {
            contentType: ContentTypeEnum.BLOB,

        },
    );
}

/**
 * 根据关键字查询商品
 */
export function searchPurchaseProducts(params) {
    return requests(
        {
            url: baseUrl + '/product/search',
            params,
            method: 'get'
        },
    );
}

/**
 * 查询可采购商品列表
 */
export function queryPurchaseProductList(params) {
    return requests(
        {
            url: baseUrl + '/product/list',
            params,
            method: 'get'
        },
    );
}
