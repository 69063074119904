// constants.js
export const AVAILABLE = [
    { text: "启用", value: "1" },
    { text: "停用", value: "0" },
]

export const PRODUCTTYPE = [
    { text: "普通商品", value: "1" },
    { text: "组合商品", value: "2" },
]

export const PURCHASE_ORDER_STATUS = [
    { text: "待审核", value: "0" },
    { text: "审核通过", value: "3" },
    { text: "审核拒绝", value: "6" },
]
export const SETTLE_STATUS = [
    { text: "未结算", value: "0" },
    { text: "结算中", value: "1" },
    { text: "已结算", value: "3" },
    { text: "无需结算", value: "6" },
]


// 定义一个通用函数，传入常量数组和value，返回对应的text
export const getTextByValue = (array, value) => {
    const item = array.find(item => item.value == value);
    return item ? item.text : undefined;
}